<template>
  <v-card class="mx-auto" outlined :loading="loading">
      <template slot="progress">
        <v-progress-linear color="deep-purple" height="3" indeterminate></v-progress-linear>
      </template>
      <v-list-item three-line >
        <v-list-item-content>
          <div class="text-overline text-center">{{titolo}}</div>
          <v-list-item-title>
          </v-list-item-title>
          <v-list-item-content>
            <apexchart type="pie" :options="chartOptions" :series="seriesQ"></apexchart>
          </v-list-item-content>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions class="d-flex justify-space-around">
      </v-card-actions>
    </v-card>    
  </template>

<script>
import _ from 'lodash'

export default {
  components: {
  },
  props: {
    enti: Array,
    titolo: String,
    loading: Boolean
  },
  data() {
    return {
      filter: null,
      espanso: false
    }
  },
  computed: {
    seriesQ() {
      return this.enti.map(x => x.count)
    },
    seriesV() {
      return []
    },
    seriesLabels() {
      return this.enti ? this.enti.map(x => x.fornitore) : []
    },    
    chartOptions() {
      return {
        chart: {
          type: 'pie'
        },
        labels: this.seriesLabels
      }
    }    
  }
}

</script>