<template>
  <v-card class="mx-auto" outlined :loading="loading">
      <template slot="progress">
        <v-progress-linear color="deep-purple" height="3" indeterminate></v-progress-linear>
      </template>
      <v-list-item three-line >
        <v-list-item-content>
          <div class="text-overline text-center">{{titolo}}</div>
          <v-list-item-title>
          </v-list-item-title>
          <v-list-item-content>
            <apexchart type="bar" :options="chartOptions" :series="series" height="700"></apexchart>
          </v-list-item-content>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions class="d-flex justify-space-around">
      </v-card-actions>
    </v-card>    
  </template>

<script>

export default {
  components: {
  },
  props: {
    enti: Array,
    titolo: String,
    loading: Boolean
  },
  data() {
    return {
      filter: null,
      espanso: false
    }
  },
  computed: {
    series() {
      // const elenco = this.enti.map(x => { return { x: x.fornitore, y: x.count }})
      const elenco = this.enti.map(x => x.count)
      // return { name: 'Asseverazioni', data: elenco }
      return [{ name: 'Asseverazioni', data: elenco }]
    },
    seriesLabels() {
      return this.enti ? this.enti.map(x => x.fornitore) : []
    },    
    chartOptions() {
      return {
        chart: {
          type: 'bar'
        },
/*         series: [{
          name: 'Asseverazioni',
          data: this.series
        }], */
        xaxis: {
          categories: this.seriesLabels
        }
/*         pie: {
          donut: {
            size: '65%',
            background: 'transparent',
            labels: {
              show: false,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
                formatter: function (val) {
                  return val
                }
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return val
                }
              },
              total: {
                show: false,
                showAlways: false,
                label: 'Total',
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0)
                }
              }
            }
          }
        }, */
        // labels: this.seriesLabels
      }
    }    
  }
}

</script>