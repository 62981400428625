<template>
  <v-container fluid dense>
  <v-row justify="space-around">
    <v-col cols="12">
      <grafico-asseverazione :enti="fornitoriCompleti" titolo="Enti in asseverazione (fornitori > 10 enti)" :loading="loading"></grafico-asseverazione>
    </v-col>
    <v-col cols="5">
      <grafico-validati :enti="fornitoriValidati" :titolo="'Enti con servizi Validati'" :loading="loading"></grafico-validati>
    </v-col>
    <v-col cols="5">
      <grafico-per-area :aree="entiPerArea" :titolo="'Enti in asseverazione per Area'" :loading="loading"></grafico-per-area>
    </v-col>     
  </v-row>
  <v-row justify="space-around">
      
  </v-row>
  </v-container>
</template>

<script>
  import selfapi from '@/services/selfapi.js'
  import GraficoValidati from './elementi/gr_validati.vue'
  import GraficoAsseverazione from './elementi/gr_asseverazione.vue'
  import GraficoPerArea from './elementi/gr_asseverazioniPerArea.vue'

  export default {
    name: 'Dashboard',
    components: {
      GraficoValidati,
      GraficoAsseverazione,
      GraficoPerArea
    },
    data: () => ({
      dashboard: null,
      loading: false,
      entiValidati: [],
      enti:[],
      fornitoriValidati: [],
      fornitoriCompleti: [],
      entiPerArea: []
    }),
    async mounted() {
      this.loading = true
      const result = await selfapi.entiAsseverabili(true, false)
      let entiRaw = result.enti
      this.entiValidati = entiRaw.filter(x => _.every(x.servizi, x=> x.validato))
      let fornitori = _.uniq(_.flatten(this.entiValidati.map((x => x.servizi.map(x => x.fornitore)))))
      this.fornitoriValidati = fornitori.map(x => { return { fornitore: x, count: 0 }})
      for (let f of this.fornitoriValidati) {
        const enti = this.entiValidati.filter(x => x.servizi.some(x => x.fornitore === f.fornitore))
        f.count = enti ? enti.length : 0
      }
      fornitori = _.uniq(_.flatten(entiRaw.map((x => x.servizi && x.servizi.map(x => x.fornitore)))))
      this.fornitoriCompleti = fornitori.map(x => { return { fornitore: x, count: 0 }})
      for (let f of this.fornitoriCompleti) {
        const enti = entiRaw.filter(x => x.servizi.some(x => x.fornitore === f.fornitore))
        f.count = enti ? enti.length : 0
      }
      this.fornitoriCompleti = (_.sortBy(this.fornitoriCompleti.filter(x => x.count > 10), ['count'])).reverse()

      const entiInAsseverazione = entiRaw.filter(x => x.inAsseverazione)
      const aree = _.uniq(entiInAsseverazione.map(x => x.area))
      for (let area of aree) {
        const enti = entiInAsseverazione.filter(x => x.area === area)
        this.entiPerArea.push({ area, count: enti.length })
      }

      this.loading = false
    },
    methods: {
    }
  }
</script>
